<template>
  <!-- 起居指导 -->
  <div class="white-bg" v-show="guidance.length" name="guide">
    <div class="pad_1">
      <div class="newr-font">起居指导</div>
      <div class="flex-row align-items module-height">
        <div
          class="newre-read-title"
          :class="{
            great: resultArr && resultArr.length == 0,
            active: btnIndex9 == index,
          }"
          v-for="(item, index) in guidance"
          :key="index"
          @click="btnClik(index, 9)"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        v-for="(item, index) in guidance"
        :key="index"
        v-show="btnIndex9 == index"
      >
        <div v-for="(item, index) in item.value" :key="index">
          <div class="newre-zh-box newre-background">
            <div class="newre-zh-title">生活指导</div>
            <div class="newre-bottom">{{ item.guide }}</div>
          </div>
          <div class="newre-zh-box newre-background">
            <div class="newre-zh-title">饮食指导</div>
            <div class="newre-bottom">{{ item.eat_guide }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hu-ask" v-if="page==10">
      <div class="ask-box">
        <div class="flex">
          <div class="syndrome-name syndrome-y" :class="{
              great: resultArr && resultArr.length == 0,
              active: btnIndex9 == index,
            }"
            v-for="(item, index) in guidance"
            :key="index"
            @click="btnClik(index, 9)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="ask-box flex">
        <div class="ask-title flex-1">
            <div class="title-mark">起居指导</div>
            <div
              v-for="(item, index) in guidance"
              :key="index"
              v-show="btnIndex9 == index"
              class="ask-common"
            >
              <div v-for="(item, index) in item.value" :key="index">
                <div class="ask-explain flex">
                  <div class="ask-explain-title">生活指导</div>
                  <div class="ask-explain-text flex-1">{{ item.guide }}</div>
                </div>
                <div class="ask-explain flex">
                  <div class="ask-explain-title">饮食指导</div>
                  <div class="ask-explain-text flex-1">{{ item.eat_guide }}</div>
                </div>
              </div>
            </div>
            <div class="assess-top-left"></div>
            <div class="assess-top-right"></div>
            <div class="assess-bottom-left"></div>
            <div class="assess-bottom-right"></div>
            <div class="page-bottom-right">
              <div class="previou" @click="pageFun(-1)">上一页</div>
            </div>
        </div>
        <div class="page"></div>
      </div>
  </div>
</template>
<script>
export default {
  props: ["resultArr", "content", "asset_prefix","page"],
  data() {
    return {
      btnIndex9: 0,
    };
  },
  computed: {
    //起居指导
    guidance() {
      let arr = [];
      if (this.content) {
        if (this.content.guide) {
          let data = this.content.guide;
          for (let key in data) {
            let obj = {value:[]};
              obj.name = key;
              for(let num in data[key]){
                obj.value.push(data[key][num])
              }
              arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    },
  },
  methods: {
    btnClik(index, type) {
      this.btnIndex9 = index;
    },
    pageFun(page){
      this.$emit('pageFun',page)
    }
  },
};
</script>
