<template>
  <!-- 药茶养生 -->
  <div class="white-bg" v-show="tea.length" name="tea">
    <div class="pad_1">
      <div class="newr-font">药茶养生</div>
      <div class="flex-row align-items module-height">
        <div
          class="newre-read-title"
          :class="{
            great: resultArr && resultArr.length == 0,
            active: btnIndex4 == index,
          }"
          v-for="(item, index) in tea"
          :key="index"
          @click="btnClik(index, 4)"
        >
          {{ item.name }}
        </div>
      </div>

      <div
        v-show="btnIndex4 == index"
        class="rd-plan-item"
        v-for="(item, index) in tea"
        :key="index + 'tea'"
      >
        <div v-for="(item, index) in item.value" :key="index">
          <div class="newre-zh-box newre-background">
            <div class="newre-hl-tea-title">{{ item.tea_name }}</div>
            <div class="newre-zh-title">配料</div>
            <div class="newre-bottom">{{ item.peifang }}</div>
            <div class="newre-zh-title">泡煮方法</div>
            <div class="newre-bottom">{{ item.method }}</div>
            <div class="newre-zh-title">适合人群</div>
            <div class="newre-bottom">{{ item.person }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hu-ask" v-if="page==3">
    <div class="ask-box no-ask">
      <div class="flex">
        <div class="syndrome-name syndrome-y"
          :class="{
            great: resultArr && resultArr.length == 0,
            active: btnIndex4 == index,
          }"
          v-for="(item, index) in tea"
          :key="index"
          @click="btnClik(index, 4)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="ask-box no-ask flex">
        <div class="ask-title flex-1">
          <div class="title-mark">药茶养生</div>
          <div
            v-show="btnIndex4 == index"
            v-for="(item, index) in tea"
            :key="index + 'tea'"
            class="ask-tea flex "
          >
            <div v-for="(item, index) in item.value" :key="index" >
                <div class="syndrome-name syndrome-tea" :class="{
                  active: index==teaIndex,
                }" @click="teaClik(index)">{{ item.tea_name }}</div>
            </div>
          </div>
          
          <div
            v-show="btnIndex4 == index"
            v-for="(item, index) in tea"
            :key="index + 'tea'"
          >
            <div v-if="item.value.length>0">
              <div v-for="(item, index) in item.value" :key="index">
                <div v-if="index==teaIndex">
                  <div class="ask-explain flex">
                      <div class="ask-explain-title">配料</div>
                      <div class="ask-explain-text flex-1">{{ item.peifang }}</div>
                  </div>
                  <div class="ask-explain flex">
                      <div class="ask-explain-title">泡煮方法</div>
                      <div class="ask-explain-text flex-1">{{ item.method }}</div>
                  </div>
                  <div class="ask-explain flex">
                      <div class="ask-explain-title">适合人群</div>
                      <div class="ask-explain-text flex-1">{{ item.person }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="assess-top-left"></div>
          <div class="assess-top-right"></div>
          <div class="assess-bottom-left"></div>
          <div class="assess-bottom-right"></div>
          <div class="page-bottom-right">
            <div class="previou" @click="pageFun(-1)">上一页</div>
            <div class="next" @click="pageFun(1)">下一页</div>
          </div>
      </div>
      <div class="page"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["resultArr", "content", "asset_prefix" ,"page"],
  data() {
    return {
      btnIndex4: 0,
      btnIndex5: 0,
      btnIndex6: 0,
      btnIndex7: 0,
      btnIndex8: 0,
      btnIndex9: 0,
      btnIndex10: 0,
      teaIndex:0
    };
  },
  computed: {
    //药茶养生
    tea() {
      let arr = [];
      if (this.content) {
        if (this.content.tea) {
          let data = this.content.tea;
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    },
  },
  methods: {
    btnClik(index, type) {
      if (type == 4) {
        this.btnIndex4 = index;
      }
    },
    teaClik(index){
      this.teaIndex = index;
    },
    pageFun(page){
      if(this.cpage==0 && page>0){
        this.cpage = 1;
      }else if(this.cpage==1 && page<0){
        this.cpage = 0;
      }else{
        this.$emit('pageFun',page)
      }
    }
  },
};
</script>
