<template>
  <!-- 艾灸穴位 -->
  <div class="white-bg" v-show="aijiu.length" name="aijiu">
    <div class="pad_1">
      <div class="newr-font">艾灸穴位</div>
      <div class="flex-row align-items module-height">
        <div
          @click="btnClik(index, 11)"
          v-for="(item, index) in aijiu"
          :key="index"
          class="newre-read-title"
          :class="{
            great: resultArr && resultArr.length == 0,
            active: btnIndex11 == index,
          }"
        >
          {{ item.name }}
        </div>
      </div>
      <aijiuchild
        v-show="btnIndex11 == index"
        v-for="(item, index) in aijiu"
        :key="index"
        :datalist="item.value"
        :asset_prefix="asset_prefix"
        :cpage="cpage"
        :cIndex="index"
      >
      </aijiuchild>
      <div class="aijiu-tip">
        （注：中医指的“寸”为同身寸，指以本人的某些部位来测定自身，一般以本人拇指指关节的宽度作为一寸。）
      </div>
    </div>
  </div>
  <div class="hu-ask" v-if="page==4">
    <div class="ask-box no-ask">
      <div class="flex">
        <div class="syndrome-name syndrome-y"
          :class="{
            great: resultArr && resultArr.length == 0,
            active: btnIndex4 == index,
          }"
          v-for="(item, index) in tea"
          :key="index"
          @click="btnClik(index, 4)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="ask-box no-ask">
      <div class="flex">
        <div class="syndrome-name syndrome-y"
          @click="btnClik(index, 11)"
          v-for="(item, index) in aijiu"
          :key="index"
          :class="{
            great: resultArr && resultArr.length == 0,
            active: btnIndex11 == index,
          }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="ask-box no-ask flex">
      <div class="ask-title flex-1">
          <div class="title-mark">艾灸穴位</div>
          <aijiuchild
            v-show="btnIndex11 == index"
            v-for="(item, index) in aijiu"
            :key="index"
            :datalist="item.value"
            :asset_prefix="asset_prefix"
            :cpage="cpage"
            :cIndex="index"
          >
          </aijiuchild>
          <div class="syndrome-censor">
            （注：中医指的“寸”为同身寸，指以本人的某些部位来测定自身，一般以本人拇指指关节的宽度作为一寸。）
          </div>
          <div class="assess-top-left"></div>
          <div class="assess-top-right"></div>
          <div class="assess-bottom-left"></div>
          <div class="assess-bottom-right"></div>
          <div class="page-bottom-right">
            <div class="previou" @click="pageFun(-1)">上一页</div>
            <div class="next" @click="pageFun(1)">下一页</div>
          </div>
      </div>
      <div class="page"></div>
    </div>
  </div>
</template>
<script>
import aijiuchild from "./aijiuchild";
export default {
  components: { aijiuchild },
  props: ["resultArr", "content", "asset_prefix","page"],
  data() {
    return {
      btnIndex11: 0,
      cpage:0
    };
  },
  computed: {
    //艾灸养生
    aijiu() {
      let arr = [];
      if (this.content) {
        if (this.content.aijiu) {
          let data = this.content.aijiu;
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    },
  },
  methods: {
    btnClik(index, type) {
      if (type == 11) {
        this.btnIndex11 = index;
      }
    },
    pageFun(page){
      if(this.$props.resultArr.length>1){
          if(this.cpage+page<this.$props.resultArr.length && this.cpage+page>=0){
              this.cpage = this.cpage+page;
          }else{
            this.$emit('pageFun',page)
          }
      }else{
          this.$emit('pageFun',page)
      }  
    }
  },
};
</script>
