<template>
<div v-show="guidance.length">
  <div class="newre-rem balance">
      <div class="balance-risk">
          <div class="risk ">
              起居指导
          </div>
      </div>
      <div class="syndrome-list flex-row">
        <div class="synd" :class="{
              active: btnIndex9 == index,
              green: item.name == '平衡'
            }"
            v-for="(item, index) in guidance"
            :key="index"
            @click="btnClik(index, 9)"
        >{{ item.name }}</div>
      </div>
      <div
        v-for="(item, index) in guidance"
        :key="index"
        v-show="btnIndex9 == index"
      >
        <div v-for="(item, index) in item.value" :key="index">
            <div class="syndrome-text syndrome-body">
                <p class="fit-title">生活指导：</p>
                <p class="fit-pei">{{ item.guide }}</p>
                <p class="fit-title">饮食指导：</p>
                <p class="fit-pei">{{ item.eat_guide }}</p>
            </div>
        </div>
      </div>
  </div>
</div>
</template>
<script>
export default {
  props: ["content", "asset_prefix"],
  data() {
    return {
      btnIndex4: 0,
      btnIndex5: 0,
      btnIndex6: 0,
      btnIndex7: 0,
      btnIndex8: 0,
      btnIndex9: 0,
      btnIndex10: 0,
    };
  },
  computed: {
    //起居指导
    guidance() {
      let arr = [];
      if (this.content) {
        let content = this.content,data=[];
        for(let i=0,n=content.length;i<n;i++){
          if(content[i].alias=='guide'){
            data = content[i].value;
          }
        }
        if (data) {
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
  },
  },
  methods: {
    btnClik(index, type) {
      if (type == 4) {
        this.btnIndex4 = index;
      } else if (type == 5) {
        this.btnIndex5 = index;
      } else if (type == 6) {
        this.btnIndex6 = index;
      } else if (type == 7) {
        this.btnIndex7 = index;
      } else if (type == 8) {
        this.btnIndex8 = index;
      } else if (type == 9) {
        this.btnIndex9 = index;
      } else if (type == 10) {
        this.btnIndex10 = index;
      }
    },
  },
};
</script>
