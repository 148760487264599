<template>
  <!-- 经络养生 -->
  <div class="white-bg" v-show="jingluo.length" name="jingluo">
    <div class="pad_1">
      <div class="newr-font">经络养生</div>
      <div class="flex-row align-items module-height">
        <div
          class="newre-read-title"
          :class="{
            great: resultArr && resultArr.length == 0,
            active: teaIndex == index,
          }"
          v-for="(item, index) in jingluo"
          :key="index"
          @click="teaInd(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        v-show="teaIndex == index"
        v-for="(item, index) in jingluo"
        :key="index"
        class="rd-plan-item"
      >
        <div class="flex-row align-items overflow">
          <div
            @click="btnClik(index, 11)"
            v-for="(item, index) in item.value"
            :key="index"
            class="newre-read-txt"
            :class="{
              active: btnIndex == index,
            }"
            v-show="item.title"
          >
            {{ item.title }}
          </div>
        </div>
        <div
          v-for="(item, index) in item.value"
          :key="index"
          v-show="btnIndex == index"
        >
          <div class="newre-zh-box newre-background">
            <div class="newre-zh-title">功效</div>
            <div class="newre-bottom">{{ item.content }}</div>
            <img class="food-img" :src="asset_prefix + item.images" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hu-ask" v-if="page==5">
    <div class="ask-box">
      <div class="flex">
        <div class="syndrome-name syndrome-y"
          :class="{
            great: resultArr && resultArr.length == 0,
            active: teaIndex == index,
          }"
          v-for="(item, index) in jingluo"
          :key="index"
          @click="teaInd(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="ask-box flex">
      <div class="ask-title flex-1">
          <div class="title-mark">经络养生</div>
          <div
            v-show="teaIndex == index"
            v-for="(item, index) in jingluo"
            :key="index"
            
          >
            <div class="ask-tea flex">
              <div class="syndrome-name syndrome-tea"
                @click="btnClik(index, 11)"
                v-for="(item, index) in item.value"
                :key="index"
                :class="{
                  active: btnIndex == index,
                }"
                v-show="item.title"
              >
                {{ item.title }}
              </div>
            </div>
            <div
              v-for="(item, index) in item.value"
              :key="index"
              v-show="btnIndex == index"
            >
              <div class="flex">
                <img :src="asset_prefix + item.images" alt="" class="effect-png" />
                <div class="flex flex-y flex-1">
                  <div>
                    <div class="effect-title">功效</div>
                    <div class="effect-content">{{ item.content }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="assess-top-left"></div>
          <div class="assess-top-right"></div>
          <div class="assess-bottom-left"></div>
          <div class="assess-bottom-right"></div>
          <div class="page-bottom-right">
            <div class="previou" @click="pageFun(-1)">上一页</div>
            <div class="next" @click="pageFun(1)">下一页</div>
          </div>
      </div>
      <div class="page"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["resultArr", "content", "asset_prefix","page"],
  data() {
    return {
      teaIndex: 0,
      btnIndex: 0,
    };
  },
  computed: {
    //经络养生
    jingluo() {
      let arr = [];
      if (this.content) {
        if (this.content.jingluo) {
          let data = this.content.jingluo;
          for (let key in data) {
            let obj = {value:[]};
              obj.name = key;
              for(let num in data[key]){
                obj.value.push(data[key][num])
              }
              arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    },
  },
  mounted() {
   
  },
  methods: {
    teaInd(index) {
      this.teaIndex = index;
    },
    btnClik(index, type) {
      if (type == 11) {
        this.btnIndex = index;
      }
    },
    pageFun(page){
      this.$emit('pageFun',page)
    }
  },
};
</script>
