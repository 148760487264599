<template>
  <!-- 经典药方 -->
  <div v-show="prescript.length">
    <div class="newre-rem balance">
      <div class="balance-risk">
          <div class="risk ">
              经典药方
          </div>
      </div>
      <div class="syndrome-list flex-row">
        <div class="synd" :class="{
              active: btnIndex6 == index,
              green: item.name == '平衡'
            }"
            v-for="(item, index) in prescript"
            :key="index"
            @click="btnClik(index, 6)"
        >{{ item.name }}</div>
      </div>
      <div v-for="(item, index) in prescript"
           :key="index"
           v-show="btnIndex6 == index"
      >
          <div v-for="(paper, index) in item.value" :key="index">
            <h6 class="influence">
                {{ paper.title }}
            </h6>
            <div class="syndrome-text syndrome-body">
                <p class="fit-title">药物组成</p>
                <p class="fit-pei">{{ paper.component }}</p>
                <p class="fit-title">经方功效</p>
                <p class="fit-pei">{{ paper.prescrip }}</p>
                <img v-if="item.images"
                class="food-img"
                :src="asset_prefix+item.images" alt=""/>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["content", "asset_prefix"],
  data() {
    return {
      btnIndex4: 0,
      btnIndex5: 0,
      btnIndex6: 0,
      btnIndex7: 0,
      btnIndex8: 0,
      btnIndex9: 0,
      btnIndex10: 0,
    };
  },
  computed: {
    //经典药方
    prescript() {
      let arr = [];
      if (this.content) {
        let content = this.content,data=[];
        for(let i=0,n=content.length;i<n;i++){
          if(content[i].alias=='prescript'){
            data = content[i].value;
          }
        }
        if (data) {
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    },
  },
  methods: {
    btnClik(index, type) {
      if (type == 4) {
        this.btnIndex4 = index;
      } else if (type == 5) {
        this.btnIndex5 = index;
      } else if (type == 6) {
        this.btnIndex6 = index;
      } else if (type == 7) {
        this.btnIndex7 = index;
      } else if (type == 8) {
        this.btnIndex8 = index;
      } else if (type == 9) {
        this.btnIndex9 = index;
      } else if (type == 10) {
        this.btnIndex10 = index;
      }
    },
    goShop(item) {
      window.location.href = item;
    },
  },
};
</script>
