<template>
<div v-show="aijiu.length">
    <div class="newre-rem balance">
        <div class="balance-risk">
            <div class="risk ">
                艾灸养生
            </div>
        </div>
        <div class="syndrome-list flex-row" >
          <div class="synd" :class="{
                active: btnIndex11 == index,
                green: item.name == '平衡'
              }"
              v-for="(item, index) in aijiu"
              :key="index"
              @click="btnClik(index, 11)"
          >{{ item.name }}</div>
        </div>
        <aijiuchild
          v-show="btnIndex11 == index"
          v-for="(item,index) in aijiu"
          :key="index"
          :datalist="item.value"
          :asset_prefix="asset_prefix"
        >
        </aijiuchild>
        <div class="syndrome-text syndrome-body">
            <p>（注：中医指的“寸”为同身寸，指以本人的某些部位来测定自身，一般以本人拇指指关节的宽度作为一寸。）</p>
        </div>
    </div>
</div>
</template>
<script>
import aijiuchild from "./aijiuchild";
export default {
  components: { aijiuchild },
  props: ["content", "asset_prefix"],
  data() {
    return {
      btnIndex11: 0
    };
  },
  computed: {
    //艾灸养生
    aijiu() {
      let arr = [];
      if (this.content) {
        let content = this.content,data=[];
        for(let i=0,n=content.length;i<n;i++){
          if(content[i].alias=='aijiu'){
            data = content[i].value;
          }
        }
        if (data) {
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    }
  },
  methods: {
    btnClik(index, type) {
    if (type == 11) {
        this.btnIndex11 = index;
      }
    }
  }
};
</script>
