<template>
<div v-show="jingluo.length">
  <div class="newre-rem balance">
    <div class="balance-risk">
        <div class="risk ">
            经络养生
        </div>
    </div>
    <div class="syndrome-list flex-row">
    <div class="synd" :class="{
          active: teaIndex == index,
          green: item.name == '平衡'
        }"
        v-for="(item, index) in jingluo"
        :key="index"
        @click="teaInd(index)"
    >{{ item.name }}</div>
  </div>
  <div v-show="teaIndex == index"
       v-for="(item, index) in jingluo"
       :key="index"
  >
    <div class="syndrome-list syndrome-lan">
        <div class="syndrome-down clearfix">
          <div class="synd rome-left" 
              v-for="(item, index) in item.value"
              :class="{
                  active: btnIndex == index,
              }"
              :key="index"
              @click="btnClik(index, 11)"
        >{{ item.title }}</div>
      </div>
    </div>
    <div class="syndrome-text syndrome-body"
         v-for="(item, index) in item.value"
         :key="index"
         v-show="btnIndex == index"
    >
        <p class="fit-title">功效：</p>
        <p class="fit-pei">{{ item.content }}</p>
        <img class="food-img" :src="asset_prefix + item.images" alt="" />
    </div>
  </div>
  </div>
</div>
</template>
<script>
export default {
  props: ["content", "asset_prefix"],
  data() {
    return {
      teaIndex: 0,
      jingluopre: "",
      btnIndex: 0,
    };
  },
  computed: {
    //经络养生
    jingluo() {
      let arr = [];
      if (this.content) {
        let content = this.content,data=[];
        for(let i=0,n=content.length;i<n;i++){
          if(content[i].alias=='jingluo'){
            data = content[i].value;
          }
        }
        if (data) {
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    },
  },
  mounted() {
    if (ENV_DEV) {
      this.jingluopre = "http://api.quanmai.cloud/saas-health-check/v1/";
    } else {
      this.jingluopre = "http://api.quanmai.cloud/saas-health-check/v1/";
    }
  },
  methods: {
    teaInd(index) {
      this.teaIndex = index;
    },
    btnClik(index, type) {
      if (type == 11) {
        this.btnIndex = index;
      }
    },
  },
};
</script>
