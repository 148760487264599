<template>
  <div class="new-ios">
    <div v-if="loading">
      <div class="tips">郑重提醒：本报告不作为医学诊断及购药依据之用</div>
      <div class="newre-boxs">
          <div class="newre-rem rem-big">
              <div class="newre-head">
                <div class="portrait">
                    <div class="newre-port">
                        <img src="../../public/assets/report/rem.png" />
                    </div>
                    <div class="nick-newre">
                      <div class="newre-name">
                          {{nickname}}
                          <div class='tip newre-tip'>
                            <img src="../../public/assets/report/5.png" v-if="sex == 1" />
                            <img src="../../public/assets/report/6.png" v-else />
                            <div class="sex-li">{{ sex == 2 ? "女" : "男" }}</div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="newre-tips flex-row">
                  <div class='tip'>
                    <img src="../../public/assets/report/2.png"/>
                    <div class="sex-li">{{ birth }}岁</div>
                  </div>
                  <div class='tip'>
                    <img src="../../public/assets/report/3.png"/>
                    <div class="sex-li">{{ height }}cm</div>
                  </div>
                  <div class='tip'>
                    <img src="../../public/assets/report/4.png"/>
                    <div class="sex-li">{{weight}}kg</div>
                    <div class="fat blue" v-if="mbiText=='偏瘦'">{{ mbiText }}</div>
                    <div class="fat green" v-if="mbiText=='正常'">{{ mbiText }}</div>
                    <div class="fat" v-if="mbiText=='偏胖'">{{ mbiText }}</div>
                    <div class="fat orange" v-if="mbiText=='肥胖'">{{ mbiText }}</div>
                    <div class="fat red" v-if="mbiText=='重度肥胖'">{{ mbiText }}</div>
                  </div>
              </div>
              <div class="risk">
                  风险提示
              </div>
              <div class="assessment">
                  <div>
                    <span>健康平衡指数：</span>
                    <span class="green" v-if="sick_text.length==1&&sick_text[0]=='平衡'">{{score}}分</span>
                    <span class="orange" v-else>{{score}}分</span>
                  </div>
                  <div>
                    <span>中医偏颇证侯：</span>
                    <span class="green" v-if="sick_text.length==1&&sick_text[0]=='平衡'">{{sick_text.join('、')}}</span>
                    <span class="orange" v-else>{{sick_text.join('、')}}</span>
                  </div>
                  <div>
                    <span>常见表现：</span>
                    <span class="green" v-if="sick_text.length==1&&sick_text[0]=='平衡'">{{ viscera.syndrome_show }}</span>
                    <span class="orange" v-else>{{ viscera.syndrome_show }}</span>
                  </div>
                  <div>
                    <span>重大疾病风险预警：</span>
                    <span class="orange">{{big.join('、')}}</span>
                  </div>
                  <div>
                    <span>重点预防：</span>
                    <span class="orange">{{little.join('、')}}</span>
                  </div>
              </div>
          </div>

          <div class="newre-rem balance">
              <div class="balance-risk">
                  <div class="risk ">
                      健康平衡指数
                  </div>
              </div>
              <div id="main" class="balance-meter"></div>
              <div class="condition" v-show="0<score && score<=50">
                可能患有疾病，建议<span class="orange">就医检查</span>
              </div>
              <div class="condition" v-show="50<score && score<=65">
                身体极不平衡，建议<span class="orange">马上调理</span>
              </div>
              <div class="condition" v-show="65<score && score<=80">
                身体较不平衡，建议<span class="orange">马上调理</span>
              </div>
              <div class="condition" v-show="80<score && score<=90">
                身体较为平衡，建议<span class="green">适当调理</span>
              </div>
              <div class="condition" v-show="90<score && score<=100">
                身体平衡，建议<span class="green">适当调理</span>
              </div>
              <div class="newre-classify newre-condie">
                <div>
                  <span class="red newre-width">0-50分</span>可能患有疾病，建议<span>就医检查</span>
                </div>
                <div>
                  <span class="orange2 newre-width">50-65分</span
                  >身体极不平衡，建议<span>马上调理</span>
                </div>
                <div>
                  <span class="ching newre-width">65-80分</span
                  >身体较不平衡，建议<span>马上调理</span>
                </div>
                <div>
                  <span class="great newre-width">80-90分</span
                  >身体较为平衡，建议<span>适当调理</span>
                </div>
                <div>
                  <span class="greet2 newre-width">90-100分</span
                  >身体平衡，建议<span>适当调理</span>
                </div>
              </div>
          </div>
          <div class="newre-rem balance">
              <div class="balance-risk">
                  <div class="risk ">
                      中医证侯
                  </div>
              </div>
              <div class="syndrome">您当前的中医证侯</div>
              <div class="syndrome-list flex-row">
                <div class="synd green" v-if="sick_text.length==1&&sick_text[0]=='平衡'">{{sick_text[0]}}</div>
                <div v-else class="flex-row">
                  <div class="synd active" v-for="(item ,index) in sick_text" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="syndrome-text">
                <div v-html="viscera.syndrome_desc" class="texts"></div>
              </div>
          </div>
          <div class="newre-rem balance">
              <div class="balance-risk">
                  <div class="risk">
                      体重指数（BMI）
                  </div>
              </div>
              <div class="risk-weight flex-row">
                  <div class="my-weight">
                      <div>
                        <div class="exponent">我的指数</div>
                        <div class="weight-size">{{ MBI }}</div>
                        <div class="weight-fat blue" v-if="mbiText=='偏瘦'">{{ mbiText }}</div>
                        <div class="weight-fat green" v-if="mbiText=='正常'">{{ mbiText }}</div>
                        <div class="weight-fat" v-if="mbiText=='偏胖'">{{ mbiText }}</div>
                        <div class="weight-fat orange" v-if="mbiText=='肥胖'">{{ mbiText }}</div>
                        <div class="weight-fat red" v-if="mbiText=='重度肥胖'">{{ mbiText }}</div>
                      </div>
                  </div>
                  <div class="my-weight weight1 noWeight">
                      <div>
                        <div class="exponent">我的合适体重</div>
                        <div class="weight-size">{{ suitWeight }}<span>kg</span></div>
                      </div>
                  </div>
              </div>
              <div class="weight-thin flex-row">
                <div class="thin flex-row">
                  <div>
                    <div class="exponent">偏瘦</div>
                    <div class="weight-size">&lt;18.5</div>
                  </div>
                </div>
                <div class="thin flex-row">
                  <div>
                    <div class="exponent">正常</div>
                    <div class="weight-size">18.5～23.9</div>
                  </div>
                </div>
                <div class="thin flex-row">
                  <div>
                    <div class="exponent">偏胖</div>
                    <div class="weight-size">24～26.9</div>
                  </div>
                </div>
                <div class="thin flex-row">
                  <div>
                    <div class="exponent">肥胖</div>
                    <div class="weight-size">27～29.9</div>
                  </div>
                </div>
                <div class="thin flex-row">
                  <div>
                    <div class="exponent">重度肥胖</div>
                    <div class="weight-size">≥30</div>
                  </div>
                </div>
              </div>
          </div>
          <div class="newre-rem balance">
              <div class="balance-risk">
                  <div class="risk ">
                      健康趋势图
                  </div>
              </div>
              <div class="trend7">显示最近7次数据趋势</div>
              <div class="healthy-1 flex-row">
                  <div class="healthy">健康平衡指数</div>
              </div>
              <div class="trend-alert">指数越高，身体健康越平衡</div>
              <div class="balance-meter broken" id="broken"></div>
              <div class="healthy-1 flex-row">
                  <div class="healthy">体重指数（BMI）</div>
              </div>
              <div class="trend-alert">体重指数（BMI）= 体重/身高 ^2</div>
              <div class="balance-meter broken" id="weight"></div>
          </div>
          <div class="newre-rem balance">
              <div class="balance-risk">
                  <div class="risk ">
                      身体健康状态综合评估
                  </div>
              </div>
              <div class="syndrome-text syndrome-body" v-html="check_bmi"></div>
              <div class="syndrome-text syndrome-body" >
                  <span class="no-cloer">您的健康平衡指数为<span>{{score}}</span>分，对于<span>{{ birth }}</span>岁的<span>{{ sex == 2 ? "女" : "男" }}性</span>，这表明</span>
                  <span class="no-cloer" v-html="check_estimate"></span>
              </div>

              <h6 class="influence">
                  易受影响的脏腑组织
              </h6>
              <div class="syndrome-text syndrome-body" >
                  <p>{{viscera.viscera}}</p>
              </div>
          </div>
          <div class="newre-rem balance">
              <div class="balance-risk">
                  <div class="risk ">
                      身体各系统功能强弱表
                  </div>
              </div>
              <div class="table-mark">
                <table class="newre-table">
                    <thead>
                      <tr>
                          <th>系统功能名称</th>
                          <th>指数（5～1）</th>
                      </tr>  
                    </thead>
                    <tbody>
                      <tr v-for="(item , index) in system" :key="index">
                          <td>{{index}}</td>
                          <td>{{item}}</td>
                      </tr>
                    </tbody>  
                </table>
              </div>
              <h6 class="influence">
                  指数说明
              </h6>
              <div class="syndrome-text syndrome-body">
                  <p>"5"表示处于最佳状态</p>
                  <p>"4"表示处于健康状态</p>
                  <p>"3"表示处于轻度亚健康状态</p>
                  <p>"2"表示处于重度亚健康状态</p>
                  <p>"1"表示处于异常状态</p>
              </div>  
          </div>
          <div class="newre-rem balance">
              <div class="balance-risk">
                  <div class="risk ">
                      健康风险提示
                  </div>
              </div>
              <div class="syndrome-text syndrome-body">
                  <p>根据您的性别年龄和上述各项健康指标，结合临床大数据研究显示，相对于同年龄段的人群您患<span>{{big.join('、')}}</span>的概率更高。大部分疾病的发生和传变都是一个漫长的过程，根本原因在于身体健康状态的失衡。如果诸多轻症长久不调理或潜藏未觉，就会增加向重疾恶化的风险。</p>
                  <p>建议您警惕以下重大疾病：<span>{{little.join('、')}}</span>。</p>
              </div>
          </div>
          <div class="newre-rem balance">
              <div class="balance-risk">
                  <div class="risk">
                      重大疾病风险预警
                  </div>
              </div>
              <div v-for="(items, index) in bigData" :key="index">
                <h6 class="influence" >
                    {{items.name}}
                </h6>
                <div v-for="(item, index) in littleData" :key="index">
                    <div v-if="item.key==items.name" class="syndrome-text syndrome-body weight-tips">
                      <div class="tips-tumour flex-row">
                        <div class="tumour-title weight1">{{item.disease_name}}</div>
                        <div class="tumour-risk" v-if="item.risk_index<40">低风险（{{item.risk_index}}）</div>
                        <div class="tumour-risk centre" v-if="40<=item.risk_index&&item.risk_index<60">中风险（{{item.risk_index}}）</div>
                        <div class="tumour-risk centreUp" v-if="60<=item.risk_index&&item.risk_index<75">中高风险（{{item.risk_index}}）</div>
                        <div class="tumour-risk high" v-if="75<=item.risk_index">高风险（{{item.risk_index}}）</div>
                      </div>
                      <div class="syndrome-joke" v-html="item.disease_money"></div>
                    </div>
                </div>
              </div>
          </div>
          <div class="newre-rem balance">
              <div class="balance-risk">
                  <div class="risk ">
                      风险防范方案
                  </div>
              </div>
              <div class="syndrome-text syndrome-body">
                  <p>为预防"一人生病拖垮全家"的风险，建议您一方面尽早进行<span>资金上的储备和规划</span>;另一方面，及时进行身体的<span>辩证健康调理</span>。以下是根据您的综合健康状态科学设计的个性化"辩证养生"调理方案。</p>
              </div>
          </div>
          <!-- 药茶养生、艾灸养生、经络养生、药膳养生、六茶养生、经方、音乐养生、起居指导 -->
          <component
            :is="option"
            v-for="(option, index) in floatAlis"
            :content="content.float"
            :resultsArr="resultsArr"
            :asset_prefix="imgPrefix"
            :key="index"
            :shopUrl="content.shop_pre_url"
            :shopImg="content.shop_img_pre_url"
          >
          </component>
      </div>
    </div>
    <div class="no-data" v-else>
        <div>
          <img src="/static/noData.png"/>
          <p>暂无数据</p>
        </div>
    </div>
  </div>
</template>
<script>
import testService from "../services/testService";
import tea from "./newComponents/tea";
import aijiu from "./newComponents/aijiu";
import jingluo from "./newComponents/jingluo";
import food from "./newComponents/food";
import sixTea from "./newComponents/sixTea";
import prescript from "./newComponents/prescript";
import music from "./newComponents/music";
import guide from "./newComponents/guide";
import "./report.scss";
import * as echarts from 'echarts';
export default {
  components: {
    tea,
    aijiu,
    jingluo,
    food,
    sixTea,
    prescript,
    music,
    guide,
  },
  data() {
    return {
      Id: "",
      nickname:"",
      isWeichat: false,
      target: "", //目标
      userId: null,
      reportId: null,
      details: {},
      content: {},
      btnIndex: 0,
      str: "",
      new_tea_list: "", //茶饮数据
      show: false, //是否显示报告
      faceShow: false, //面诊舌诊
      inputShow: false,
      date: [],
      value: [],
      myChart: null,
      MBI: "", //指数
      title: "",
      imgPre: "",
      link: null, //分享链接
      sub_desc: "",
      img: "",
      timeout: null,
      dreamTarget: "",
      sicknessList: [],
      isFirstTarget: false, //首次写目标
      targetSuccess: false, //目标是否完成
      currentTarget: null, //当前目标
      nextTarget: null, //下一个目标
      isNewCheck: false,
      score: 0,
      hundredScoreTotal: 0,
      averscorePos: 0,
      noshow: true,
      suitWeight: "",
      results: [],
      birth: 0,
      sex: 0,
      height: 0,
      resultsArr: [],
      weight:0,
      viscera:[],
      sick_text:[],
      big:[],
      little:[],
      check_bmi:'',
      check_estimate:'',
      system:'',
      bigData:[],
      littleData:[],
      loading:true,
      imgPrefix:'',
      mbiText:'',
      floatAlis:[]
    };
  },
  computed: {
    type() {
      return this.$route.query.type;
    },

    // 易感疾病
    sickness() {
      var arr = [];
      if (this.content.sickness) {
        let data = this.content.sickness;
        for (let n in data) {
          var obj = {};
          obj.symbol_title = data[n].symbol_title;
          obj.sickness = data[n].sickness;
          arr.push(obj);
        }
        return arr;
      } else {
        return arr;
      }
    },
    code() {
      return this.$route.query.code;
    },
  },
  mounted() {
    //体重
    this.getReport();
    scrollTo(0,0);
    //this.getuser();
  },
  methods: {
    //仪表盘
    meter(fraction){
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option
      option = {
          series: [{
              type: 'gauge',
              startAngle: 180,
              endAngle: 0,
              min: 0,
              max: 1,
              splitNumber: 8,
              axisLine: {
                  lineStyle: {
                      width: 6,
                      color: [
                          [0.5, '#F36440'],
                          [0.65, '#F89143'],
                          [0.8, '#ECDB44'],
                          [0.9, '#5CC7BC'],
                          [1, '#83D186']
                      ]
                  }
              },
              axisTick: {
                  length: 12,
                  lineStyle: {
                      color: 'auto',
                      width: 2
                  }
              },
              splitLine: {
                  length: 20,
                  lineStyle: {
                      color: 'auto',
                      width: 5
                  }
              },
              axisLabel: {
                  color: '#464646',
                  fontSize: 15,
                  distance: -60,
                  formatter: function (value) {}
              },
              detail: {
                  fontSize: 30,
                  offsetCenter: [0, '40%'],
                  valueAnimation: true,
                  formatter: function (value) {
                      return Math.round(value * 100) + '分';
                  },
                  color: 'auto'
              },
              data: [{
                  value: fraction/100,
              }]
          }]
      };
      option && myChart.setOption(option);
    },

    MbiTextFun(MBI) {
      if (MBI <= 18.5) {
        return "偏瘦";
      } else if (MBI >= 18.5 && MBI <= 23.9) {
        return "正常";
      } else if (MBI >= 24 && MBI <= 26.9) {
        return "偏胖";
      } else if (MBI >= 27 && MBI <= 29.9) {
        return "肥胖";
      } else if (MBI >= 30) {
        return "重度肥胖";
      }
    },

    broken(dom,date,data,obj){
      var chartDom = document.getElementById(dom);
      var myChart = echarts.init(chartDom);
      var option;

      var newData =[], newDate =[];
      for(let i=0,n=data.length;i<n;i++){
        if(data[i]!==0){
          newData.push(data[i]);
          newDate.push(date[i]);
        }
      }


      option = {
          xAxis: {
              type: 'category',
              data: newDate
          },
          yAxis: {
              type: 'value',
              ...obj
          },
          series: [{
              color:'rgb(181,209,202)',
              data: newData,
              type: 'line'
          }]
      };

      option && myChart.setOption(option);
    },

    btnClik(index) {
      this.btnIndex = index;
    },

    //获取报告
    getReport() {
      //Indicator.open("加载中");
      testService.prototype
        .getReportData({
          user_id:this.$route.query.userId,
          id:this.$route.query.reportId
        }).then((data) => {
          if (!data.code) {
              let res = data.data; 
              this.weight = res.base.weight;
              this.MBI = res.base.bmi;
              this.sex = res.base.sex;
              this.nickname=res.base.user_nikename;
              this.height = res.base.height;
              this.suitWeight = res.base.suit_weight;
              this.birth = res.base.age;
              this.weight = res.base.weight;
              this.viscera = res.base.viscera;
              this.sick_text = res.base.sick_text_array;
              this.resultsArr = res.base.sick_text_array;
              this.check_bmi = res.base.check_bmi;
              this.check_estimate = res.base.check_estimate;
              this.system = res.base.system;
              this.imgPrefix = res.asset_prefix;
              this.mbiText = this.MbiTextFun(res.base.bmi);

              let big = [] , little = [] ,bigData = [],littleData=[];
              for( let item in res.base.syndrome_danger ){
                  let hot = 0
                  for(let i=0,n=res.base.syndrome_danger[item].list.length;i<n;i++){
                    if(res.base.syndrome_danger[item].list[i].risk_index != 0){
                        hot++;
                        little.push(res.base.syndrome_danger[item].list[i].disease_name);
                        res.base.syndrome_danger[item].list[i].key = res.base.syndrome_danger[item].name;
                        littleData.push(res.base.syndrome_danger[item].list[i]);
                    }
                  }
                  if(hot>0){
                      big.push(res.base.syndrome_danger[item].name);
                      bigData.push(res.base.syndrome_danger[item]);
                  }
              }

              this.big = big;
              this.little = little;
              this.bigData = bigData;
              this.littleData = littleData;

              //健康平衡,体重
              let score=[],date=[],bmi=[],bmiDate=[];
              //健康分数
              let health=0;
              for(let i=0,n=res.module.fix.length;i<n;i++){
                if(res.module.fix[i].title == '健康分数'){
                  health = res.module.fix[i].value
                }
                if(res.module.fix[i].title == '健康趋势'){
                  for(let y=0,j=res.module.fix[i].value.list.length;y<j;y++){
                      score.push(res.module.fix[i].value.list[y].score);
                      let dateTime =  new Date(res.module.fix[i].value.list[y].time);
                      date.push(`${dateTime.getMonth()+1}.${dateTime.getDate()}`)
                  }
                  for(let h=0,g=res.module.fix[i].value.bmi.length;h<g;h++){
                      bmi.push(res.module.fix[i].value.bmi[h].bmi);
                      let bmiTime =  new Date(res.module.fix[i].value.bmi[h].time);
                      bmiDate.push(`${bmiTime.getMonth()+1}.${bmiTime.getDate()}`)
                  }
                }
              }
              //仪表盘
              this.meter(health);
              //健康平衡
              this.broken('broken',date,score,{
                min:0,
                max:100
              });
              //体重
              this.broken('weight',bmiDate,bmi,{});
              this.show = true;
              this.score = health;

              var content = res.module;
              var float = content.float;
              var floatAlis = [];

              if (float) {
                if (float.length) {
                  float.map(item => {
                    if (item.alias == "six_tea") {
                      floatAlis.push("sixTea");
                    } else {
                      floatAlis.push(item.alias);
                    }
                  });
                }
              }
              this.content = content;
              this.float  = float;
              this.floatAlis = floatAlis;
          }
        })
        .catch((error) => {

        });
    },
    //隐藏显示
    faceTongue() {
      this.faceShow = !this.faceShow;
    },
    //目标显示
    setTargetShow() {
      this.inputShow = true;
    },
    //设置目标
    setTarget() {
      // testService.prototype
      //   .setTarget({
      //     Id: this.reportId, // 报告id !!required!!
      //     Target: this.target, // 下次目标值 !!required!!
      //   })
      //   .then((res) => {
      //     // Toast({
      //     //   message: "设置成功",
      //     //   position: "bottom",
      //     // });
      //     clearTimeout(this.timeout);
      //   })
      //   .catch((err) => {});
    },
    //获取评估数据-根据最近评估次数
    getDate() {
      // testService.prototype
      //   .GetScoreByTime({
      //     Method: 2,
      //     HealthUserId: this.Id,
      //   })
      //   .then((res) => {
      //     if (res.list) {
      //       for (let n of res.list) {
      //         this.date.push(
      //           n.time
      //             .split(" ")
      //             .splice(0, 1)
      //             .join("")
      //             .split("-")
      //             .splice(1, 2)
      //             .join("-")
      //         );
      //         this.value.push(n.score);
      //       }
      //       // 绘制图表
      //       this.$nextTick(function () {
      //         this.myChart = echarts.init(document.getElementById("lineChart"));
      //         this.myChart.setOption({
      //           title: {
      //             show: false,
      //           },
      //           tooltip: {},
      //           xAxis: {
      //             data: this.date,
      //             type: "category",
      //             axisLabel: {
      //               color: "#B7B7B7",
      //             },
      //             boundaryGap: false,
      //             axisTick: {
      //               length: 7,
      //             },
      //             //设置轴线的属性
      //             axisLine: {
      //               lineStyle: {
      //                 color: "#B7B7B7",
      //                 width: 2, //这里是为了突出显示加上的
      //               },
      //             },
      //           },
      //           yAxis: {
      //             min: 0,
      //             max: 100,
      //             interval: 20,
      //             axisLabel: {
      //               color: "#B7B7B7", //y轴文字颜色
      //             },
      //             //设置轴线的属性
      //             axisLine: {
      //               lineStyle: {
      //                 color: "#B7B7B7", //y轴颜色
      //                 width: 2, //这里是为了突出显示加上的
      //               },
      //             },
      //           },
      //           color: "#0087FB",
      //           dataZoom: {
      //             type: "inside",
      //             startValue: 0,
      //             endValue: 6,
      //           },
      //           series: [
      //             {
      //               type: "line",
      //               symbol: "circle", //折线转折点形状
      //               symbolSize: 7, // 转折点大小
      //               data: this.value,
      //             },
      //           ],
      //         });
      //       });
      //     }
      //   });
    },
    //返回报告列表
    backReport() {
      if (this.$route.query.type) {
        this.$router.back("-1");
      } else {
        this.$router.push({
          path: "/test/myrelist",
          query: {
            userId: this.userId,
            Id: this.Id, //主键id
          },
        });
      }
    },
    //记录
    record() {
      this.$router.replace("/archive/chart");
    },
    //保存
    Save() {
      this.timeout = setTimeout(() => {
        if (this.target) {
          if (this.target > 100) {
            // Toast({
            //   message: "目标不能大于100",
            //   position: "bottom",
            // });
          } else {
            this.setTarget();
          }
        } else {
          clearTimeout(this.timeout);
        }
      }, 5000);
    },
  },
};
</script>
