<template>
<div v-show="tea.length">
  <div class="newre-rem balance">
    <div class="balance-risk">
        <div class="risk ">
            药茶养生
        </div>
    </div>

    <div class="syndrome-list flex-row">
      <div class="synd" :class="{
            active: btnIndex4 == index,
            green: item.name == '平衡'
          }"
          v-for="(item, index) in tea"
          :key="index"
          @click="btnClik(index, 4)"
      >{{ item.name }}</div>
    </div>
    
    <div v-show="btnIndex4 == index"
         v-for="(item, index) in tea"
         :key="index + 'tea'"
    >
        <div v-for="(val, index) in item.value" :key="index">
            <h6 class="influence">
                {{ val.tea_name }}
            </h6>
            <div class="syndrome-text syndrome-body">
                <p class="fit-title">配料：</p>
                <p class="fit-pei">{{ val.peifang }}</p>
                <p class="fit-title">泡煮方法：</p>
                <p class="fit-pei">{{ val.method }}</p>
                <p class="fit-title">适合人群：</p>
                <p class="fit-pei">{{ val.person }}</p>
            </div>
            <div class="syndrome-shop flex-row" v-show="val.shop_url">
                <div class="syndrome-tea flex-row">
                  <div class="syndrome-img">
                      <img :src="shopImg+val.goods_img"/>
                  </div>
                  <div class="tea-box">
                      <p>{{ val.product_name }}</p>
                      <i><span>¥</span>{{ val.shop_price }}</i>
                  </div>
                </div>
                <div class="syndrome-look" @click="goShop(val.shop_url)">前往查看</div>
            </div>
        </div>
        <div class="syndrome-mose" @click="goMore()" v-show="shopUrl">
            <img src="/static/1.png" />
            <div>更多好物</div>
        </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  props: ["content", "asset_prefix","shopUrl","shopImg"],
  data() {
    return {
      btnIndex4: 0,
      btnIndex5: 0,
      btnIndex6: 0,
      btnIndex7: 0,
      btnIndex8: 0,
      btnIndex9: 0,
      btnIndex10: 0,
    };
  },
  computed: {
    //药茶养生
    tea() {
      let arr = [];
      if (this.content) {
        let content = this.content,data=[];
        for(let i=0,n=content.length;i<n;i++){
          if(content[i].alias=='tea'){
            data = content[i].value;
          }
        }
        if (data) {
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    },
  },
  methods: {
    btnClik(index, type) {
      if (type == 4) {
        this.btnIndex4 = index;
      } else if (type == 5) {
        this.btnIndex5 = index;
      } else if (type == 6) {
        this.btnIndex6 = index;
      } else if (type == 7) {
        this.btnIndex7 = index;
      } else if (type == 8) {
        this.btnIndex8 = index;
      } else if (type == 9) {
        this.btnIndex9 = index;
      } else if (type == 10) {
        this.btnIndex10 = index;
      }
    },
    goShop(item) {
      window.location.href = item;
    },
    //更多好物
    goMore(url) {
      window.location.href = this.shopUrl;
    },
  },
};
</script>
