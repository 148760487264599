<template>
<div>
  <div class="syndrome-list syndrome-lan">
    <div class="syndrome-down flex-row">
      <div  class="synd" 
            v-for="(item, index) in datalist"
            :class="{
              active: teaIndex == index,
              green: item.name == '平衡'
            }"
            :key="index"
            @click="teaInd(index)"
      >{{ item.title }}</div>
    </div>
  </div>
  <div v-for="(item, index) in datalist"
      :key="index"
      v-show="teaIndex == index">
    <div class="syndrome-text syndrome-body">
        <p>{{ item.tcm }}</p>
        <p>{{ item.modern_medicine }}</p>
        <div v-for="item in image(item.images)" :key="item.id">
          <img :src="asset_prefix +'/'+ item" class="rd-teap-img"/>
          <!-- <div
            :style="{ backgroundImage: 'url(' + asset_prefix +'/'+ item + ')' }"
            class="rd-teap-img"
          ></div> -->
        </div>
        <p class="fit-title">最佳饮用季节：</p>
        <p class="fit-pei">{{ season(item.season) }}</p>
        <p class="fit-title">最佳饮用时辰：</p>
        <p class="fit-pei">{{ season(item.times) }}</p>
        <p class="fit-title">冲泡器皿：</p>
        <p class="fit-pei">{{ season(item.silver) }}</p>
        <p class="fit-title">冲泡温度：</p>
        <p class="fit-pei">{{ season(item.temperature) }}</p>
        <p class="fit-title">存放方式：</p>
        <p class="fit-pei">{{ season(item.leave_with) }}</p>
        <p class="fit-title">推荐品种：</p>
        <p class="fit-pei">{{ season(item.breed) }}</p>
    </div>
  </div>
</div>
</template>
<script>
export default {
  props: ["asset_prefix", "datalist"],
  data() {
    return {
      teaIndex: 0,
    };
  },
  methods: {
    teaInd(index) {
      this.teaIndex = index;
    },
    season(type) {
      return type.replace(/<br>/g, "\n");
    },
    image(content) {
      var arr = [];
      var item = "";
      arr = content.split(",");
      return arr;
    },
    // goShop(item) {
    //   window.location.href = item;
    // },
    //更多好物
    // goMore(url) {
    //   window.location.href = url;
    // },
  },
};
</script>
