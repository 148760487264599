import { getQuanmaiApi, postQuanmaiApi } from './proxy'
export default class {
    // 获取用户列表
    GetUserList(data) {
            return getQuanmaiApi('/Checks/getUserLst', data)
        }
        //添加用户接口
    AddUser(data) {
            return postQuanmaiApi('/Checks/addUser', data)
        }
        //客户端获取用户登录状态
    ClientLogin(data) {
            return getQuanmaiApi('/Users/clientLogin', data);
        }
        //扫码获取token值接口
    getScanToken(data) {
            return getQuanmaiApi('/Users/getScanToken', data);
        }
        //获取某条评估数据
    GetSingleTestByid(userId, id,data) {
            return getQuanmaiApi('/Checks/getCheckById', {
                UserId: userId,
                Id: id,
                Platform: 1,
                ...data
            })
        }
        //获取用户信息
    GetUserById(data) {
            return getQuanmaiApi('/Checks/getUserById', data)
        }
        // 获取评估列表 ispay 1-未支付 2=-已支付
    GetTestList(data) {
            return getQuanmaiApi('/Checks/getMyLst', data)
        }
        //发送报告到微信用户
    SendToWechat(data) {
        return getQuanmaiApi('/Webchat/sendTemplate', data);
    }

    //修改用户接口
    CheckseditUser(data) {
        return postQuanmaiApi('/Checks/editUser', data);
    }

    // 上传脸部/舌头照片
    // TestImgUpload(data) {
    //     return postQuanmaiApi('/Checks/imgUpload', data)
    // }
    // 上传脸部/舌头照片(新流程)
    TestImgUpload(data) {
        return postQuanmaiApi('/Checks/newImgUpload', data)
    }

    // 获取题目
    // GetQuestion(data) {
    //         return getQuanmaiApi('/Checks/getQuestion', data)
    //     }
    // 获取题目(新流程)
    GetQuestion(data) {
            return getQuanmaiApi('/Checks/getQmQuestion', data)
        }
        /**
             * 提交评估报告
             * UserId: userId,
             * Phone  //如果用户自测
                ComUserId: ComUserId,
                Type: Type,
                FaceId: FaceId,
                TongueId: TongueId,
                Answer: Answer
             */
    SubmitReport(data) {
            return postQuanmaiApi('/Checks/submitCheck', data)
        }
        //获取评估数据-根据时间区间 ,根据最近评估次数 
    GetScoreByTime(data) {
            return getQuanmaiApi('/Checks/getScoreByTime', data);
        }
        //获取用户的评估统计数据
    getUserReport(data) {
            return getQuanmaiApi("/Checks/getUserReport", data)
        }
        // 解密接口
    GetStrByCode(str) {
            return getQuanmaiApi('/Checks/decodeStr', {
                Str: str
            });
        }
        //获取用户是否完成信息填写接口
    GetUserIsComplete(data) {
            return getQuanmaiApi('/Users/getUserIsComplete', data);
        }
        //健康排名列表接口
    scoreList(data) {
            return getQuanmaiApi('/Checks/scoreList', data);
        }
        // 点赞某个健康排名
    likeScoreUser(data) {
            return postQuanmaiApi('/Checks/likeScoreUser', data);
        }
        //判断用户是否今天有未读的排名消息接口
    getUnReadScoreMsg(data) {
            return getQuanmaiApi('/Checks/getUnReadScoreMsg', data);
        }
        //确认今日排名消息接口
    confirmScoreMsg(data) {
            return postQuanmaiApi('/Checks/confirmScoreMsg', data);
        }
        //删除用户-健康档案模块
    DelUserHealth(data) {
            return postQuanmaiApi("/Checks/delUserHealth", data)
        }
        //获取他人的体检列表
    GetOtherCheckLst(data) {
            return getQuanmaiApi("/Checks/getOtherCheckLst", data)
        }
        //设置报告目标值
    setTarget(data) {
            return postQuanmaiApi("/Checks/setTarget", data)
        }
        //获取活动列表
    getActivity(data) {
            return getQuanmaiApi("/Activitys/getActivity", data)
        }
        //获取id获取活动详情
    getDetail(data) {
            return getQuanmaiApi("/Activitys/getDetail", data)
        }
        //分享统计接口
    shareRecord(data) {
            return postQuanmaiApi("/Activitys/shareRecord", data)
        }
        //获取优惠券列表
    getCouponList(data) {
            return getQuanmaiApi("/Activitys/getCouponList", data)
        }
        //根据id获取优惠券
    getCouponInfo(data) {
            return getQuanmaiApi("/Activitys/getCouponInfo", data)
        }
        //根据报告获取茶饮及其他附属数据
    getExtraDataByReport(data) {
            return getQuanmaiApi("/Checks/getExtraDataByReport", data)
        }
        //根据分数获取报告排名数据
    getReportSortData(data) {
        return getQuanmaiApi('/Checks/getReportSortData', data)
    }
    //报告
    getReportData(data){
        return getQuanmaiApi('/report/get_third_report_info', data)
    }
}