<template>
<div>
  <div class="syndrome-list syndrome-lan">
    <div class="syndrome-down flex-row">
      <div  class="synd" 
            v-for="(item, index) in datalist"
            :class="{
              active: btnIndex == index,
              green: item.name == '平衡'
            }"
            :key="index"
            @click="btnClik(index, 11)"
      >{{ item.title }}</div>
    </div>
  </div>
  <div v-for="(item, index) in datalist" :key="index" v-show="btnIndex == index">
    <div class="syndrome-text syndrome-body">
        <p class="fit-title">穴位位置：</p>
        <p class="fit-pei">{{ item.location }}</p>
        <p class="fit-title">功效：</p>
        <p class="fit-pei">{{ item.function }}</p>
        <p class="fit-title">时长：</p>
        <p class="fit-pei">{{ item.period }}</p>
        <img class="food-img" :src="asset_prefix + item.images" alt="" />
    </div>
  </div>
</div>
</template>
<script>
export default {
  props: ["datalist", "asset_prefix"],
  data() {
    return {
      btnIndex: 0,
    };
  },
  computed: {
    
  },
  methods: {
    btnClik(index, type) {
      if (type == 11) {
        this.btnIndex = index;
      }
    },
  },
};
</script>
