<template>
  <!-- 音乐养生 -->
  <div class="white-bg" v-show="music.length" name="music">
    <div class="pad_1">
      <div class="newr-font">音乐养生</div>
      <div class="flex-row align-items module-height">
        <div
          class="newre-read-title"
          :class="{
            great: resultArr && resultArr.length == 0,
            active: btnIndex8 == index,
          }"
          v-for="(item, index) in music"
          :key="index"
          @click="btnClik(index, 8)"
        >
          {{ item.name }}
        </div>
      </div>

      <div
        class="rd-plan-item rd-content"
        v-for="(item, index) in music"
        :key="index"
        v-show="btnIndex8 == index"
      >
        <div v-for="(item, index1) in item.value" :key="index1">
          <p style="margin-bottom: 1rem" class="music-name">
            <span class="triangle"></span>
            <span>{{ item.title }}</span>
          </p>
          <div class="music-audio">
            <audio
              :src="asset_prefix + item.music_path"
              controls="controls"
              :id="`music` + index"
            >
              您的浏览器不支持html5的audio标签
            </audio>
          </div>

          <div class="newre-zh-box newre-background">
            <div class="newre-bottom">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hu-ask" v-if="page==9">
      <div class="ask-box">
        <div class="flex">
          <div class="syndrome-name syndrome-y" :class="{
              great: resultArr && resultArr.length == 0,
              active: btnIndex8 == index,
            }"
            v-for="(item, index) in music"
            :key="index"
            @click="btnClik(index, 8)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="ask-box flex">
        <div class="ask-title flex-1">
            <div class="title-mark">音乐养生</div>
            <div
              v-for="(item, index) in music"
              :key="index"
              v-show="btnIndex8 == index"
            >
              <div v-for="(item, index1) in item.value" :key="index1">
                <div class="ask-tea flex">
                  <div class="syndrome-name syndrome-tea">{{ item.title }}</div>
                </div>
                <div class="ask-audio">
                  <audio
                    :src="asset_prefix + item.music_path"
                    controls="controls"
                    :id="`music` + index"
                  >
                    您的浏览器不支持html5的audio标签
                  </audio>
                </div>
                <div class="ask-explain">
                  <div class="ask-explain-text">{{ item.content }}</div>
                </div>
              </div>
            </div>
            <div class="assess-top-left"></div>
            <div class="assess-top-right"></div>
            <div class="assess-bottom-left"></div>
            <div class="assess-bottom-right"></div>
            <div class="page-bottom-right">
              <div class="previou" @click="pageFun(-1)">上一页</div>
              <div class="next" @click="pageFun(1)">下一页</div>
            </div>
        </div>
        <div class="page"></div>
      </div>
  </div>
</template>
<script>
export default {
  props: ["resultArr", "content", "asset_prefix","page"],
  data() {
    return {
      btnIndex8: 0,
    };
  },
  computed: {
    //音乐养生
    music() {
      let arr = [];
      if (this.content) {
        if (this.content.music) {
          let data = this.content.music;
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    },
  },
  methods: {
    btnClik(index, type) {
      var audio = document.getElementById("music" + this.btnIndex8);
      if (audio !== null) {
        //检测播放是否已暂停.audio.paused 在播放器播放时返回false.
        if (audio.paused) {
          //audio.play(); // 这个就是播放
        } else {
          audio.pause(); // 这个就是暂停
        }
      }
      this.btnIndex8 = index;
    },
    pageFun(page){
      this.$emit('pageFun',page)
    }
  },
};
</script>
