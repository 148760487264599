<template>
<div>
    <div class="newre-rem balance">
        <div class="balance-risk">
            <div class="risk ">
                六茶养生
            </div>
        </div>
        <div class="syndrome-list flex-row">
          <div class="synd" :class="{
                active: btnIndex10 == index,
                green: item.name == '平衡'
              }"
              v-for="(item, index) in six_tea"
              :key="index"
              @click="btnClik(index, 10)"
          >{{ item.name }}</div>
        </div>
        <teas
          v-show="btnIndex10 == index"
          v-for="(item, index) in six_tea"
          :key="index"
          :item="item"
          :datalist="item.value"
          :asset_prefix="asset_prefix"
        >
        </teas>
    </div>
</div>
</template>
<script>
import teas from "./teas";
export default {
  components: { teas },
  props: ["content", "asset_prefix"],
  data() {
    return {
      btnIndex4: 0,
      btnIndex5: 0,
      btnIndex6: 0,
      btnIndex7: 0,
      btnIndex8: 0,
      btnIndex9: 0,
      btnIndex10: 0
    };
  },
  computed: {
    //六茶养生
    six_tea() {
      let arr = [];
      if (this.content) {
        let content = this.content,data=[];
        for(let i=0,n=content.length;i<n;i++){
          if(content[i].alias=='six_tea'){
            data = content[i].value;
          }
        }
        if (data) {
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    }
  },
  methods: {
    btnClik(index, type) {
      if (type == 4) {
        this.btnIndex4 = index;
      } else if (type == 5) {
        this.btnIndex5 = index;
      } else if (type == 6) {
        this.btnIndex6 = index;
      } else if (type == 7) {
        this.btnIndex7 = index;
      } else if (type == 8) {
        this.btnIndex8 = index;
      } else if (type == 9) {
        this.btnIndex9 = index;
      } else if (type == 10) {
        this.btnIndex10 = index;
      }
    }
  }
};
</script>
